import React from 'react'

const Cloth = ({winart, winfar, wilkum, wikwj1, wikwj2, wikwj3, wikwk1, wikwk2, wikwk3, wiofb1, wiofb2, wiofb3}) => {
  return (
    <tr className="bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700">
        <td className="bg-sky-600 border-t border-b border-l border-solid whitespace-nowrap px-6 py-4 font-medium">{winart}/{winfar}</td>
        <td className="bg-sky-600 border-t border-b border-r border-solid whitespace-nowrap px-6 py-4">{wilkum} m</td>
        <td className={wikwj1 == 0 ? "opacity-0" : "bg-sky-600 border-t border-b border-l border-solid whitespace-nowrap px-6 py-4" } >KW {wikwk1} / {wikwj1}</td>
        <td className={wikwj1 == 0 ? "opacity-0" : "bg-sky-600 border-t border-b border-r border-solid whitespace-nowrap px-6 py-4" }>{wiofb1} m</td>
        <td className={wikwj2 == 0 ? "opacity-0" : "bg-sky-600 border-t border-b border-l border-solid whitespace-nowrap px-6 py-4" } >KW {wikwk2} / {wikwj2}</td>
        <td className={wikwj2 == 0 ? "opacity-0" : "bg-sky-600 border-t border-b border-r border-solid whitespace-nowrap px-6 py-4" }>{wiofb2} m</td>
        <td className={wikwj3 == 0 ? "opacity-0" : "bg-sky-600 border-t border-b border-l border-solid whitespace-nowrap px-6 py-4" } >KW {wikwk3} / {wikwj3}</td>
        <td className={wikwj3 == 0 ? "opacity-0" : "bg-sky-600 border-t border-b border-r border-solid whitespace-nowrap px-6 py-4" }>{wiofb3} m</td>
    </tr>
    )
}

export default Cloth