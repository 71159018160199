import { Route, Routes } from "react-router-dom";
import "./locales/i18n";

import Home from "./components/pages/Home";
import Kataloge from "./components/pages/Kataloge";
import Login from "./components/pages/Login";
import Formenstamm from "./components/pages/Formenstamm";
import Products from "./components/pages/Products";
import Cloths from "./components/pages/Cloths";
import Register from "./components/pages/Register";
import Missing from "./components/pages/Missing";
import Unauthotized from "./components/pages/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/Layout";

const ROLES = {
  'admins': 1,
  'staffmembers': 2,
  'salesrepesentatives': 3,
  'customers': 4,
  'guests': 5
}

function App({language}) {


  return (
      <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public */}
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="unauthorized" element={<Unauthotized />} />
            <Route path="kataloge" element={<Kataloge />} />
            <Route path="formenstamm" element={<Formenstamm />} />
            <Route path="products" element={<Products />} />
            <Route path="cloths" element={<Cloths />} />
            {/* Editor */}
            {/* Admin */}
            <Route element={<RequireAuth allowedRoles={[ROLES.guests]} />} >
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.staffmembers, ROLES.salesrepesentatives, ROLES.customers]} />} >
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.staffmembers, ROLES.salesrepesentatives]} />} >
            </Route>
            {/* Catch all */}
            <Route path="*" element={<Missing />} />

          </Route>
        </Routes>
  );
}

export default App;