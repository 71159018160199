import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";

const LOGIN_URL = '/gettoken';

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  },[])
  useEffect(() => {
    setErrMsg('');
  },[user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(LOGIN_URL, 
        JSON.stringify({name: 'generateToken', param: { username: user, password: pwd }})
        );
      console.log(response?.data);
      console.log(response?.data?.accessToken);
      console.log(response?.data?.roles);

      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      setAuth({user, pwd, roles, accessToken});

      setUser('');
      setPwd('');
      navigate(from, { replace: true });

    } catch(err) {
      
      if(!err?.response) {
        setErrMsg('No Server Response');
      } else if(err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if(err.response?.status === 422) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
    
  }


  return (
    <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 dark:text-white w-full max-w-4xl mx-auto">
      <section className="flex flex-col text-center justify-items-center">
        <p ref={errRef} className={errMsg ? "text-red-500 block" : "hidden"} aria-live="assertive">{errMsg}</p>
        <h1 className='text-4xl text-center text-blue-400'>Login</h1>
        <form className="flex flex-col text-black w-1/3 mx-auto"
              onSubmit={handleSubmit}>
          <label htmlFor="username"
                  className="flex flex-row text-black dark:text-white mr-3"
          >
            Username:
          </label>
          <input type="text" 
                id="username"
                className="m-3 p-3 min-h-[48px] border-2 border-blue-900"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
          />
          <label htmlFor="password"
                  className=" flex flex-row text-black dark:text-white mr-3"        
          >
            Password:
          </label>
          <input type="password" 
                id="password"
                className="m-3 p-3 min-h-[48px] border-2 border-blue-900"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
          />
          <button className="p-3 dark:bg-slate-50 dbg-slate-900 dark:text-blue-900 text-white border-2 border-blue-900 rounded-tl-lg">Sign In</button>
          <p className="text-black dark:text-white">Need an Account?<br />
              <span>
                <Link to="register">Sign Up</Link>
              </span>
            </p>
        </form>
      </section>
    </div>
  )
}

export default Login