import { useEffect } from "react"
import { Link } from "react-router-dom"

const DropdownItem = ({ path, children}) => {
useEffect(() => {
    //console.log(props);
}, [])


  return (
    <li className="w-full">
        <Link to={path} className="flex p-2 mx-2 hover:bg-gray-700 dark:hover:text-white hover:border-b-[1px] hover:border-b-white">{children}</Link>
    </li>

  )
}

export default DropdownItem