import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import useDebounce from "../../hooks/useDebounce";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { t } from 'i18next';
import PaginationButtons from "../PaginationButtons";
import ItemsPerPage from "../ItemsPerPage";
import Product from "../Product";



const Products = () => {
    const {language}= useOutletContext();
    let navigate = useNavigate();
    const location = useLocation();
    const { ktlg } = location.state;
    const { katn } = location.state;
    const [search, setSearch] = useState('');
    const [products, setProducts] = useState([]);
    const perPageValues = [10, 15, 20];
    const [perPage, setPerPage] = useState(10);
    const [pageNum, setPageNum] = useState(0);
    const [itemCount, setItemCount] = useState(0);
    const debouncedSearch = useDebounce(search, 500);
    const axiosPrivate = useAxiosPrivate();
    const effectRun = useRef(false);
    const [cookie, setCookie] = useState(false);
    const [loading, setLoading] = useState(true);



                                                         
    useEffect(() => {
      setLoading(true);
      let isMounted = true;
      const controller = new AbortController();
      const getKatalogs = async () => {
          try {
              const response = await axiosPrivate.post("/products", 
              JSON.stringify({'name': 'getAllProducts', 'param': {
                'companyID': '11',
                'katalogID': `${ktlg}`,
                'kathegorieID': `${katn}`,
                'records': `${perPage}`,
                'offset': `${pageNum}`
              }}),
              {
                  signal: controller.signal
              });
              isMounted && setItemCount(parseInt(Math.ceil(response.data[0]['count']/`${perPage}`), 10));
  
          } catch(err) {
              // console.error(err);
          }
      }
      // if(effectRun.current){
        getKatalogs();
      // }
      return () => {
          console.log('cleanup function');
          isMounted = false;
          controller.abort();
          effectRun.current = true;
      }
    },[])
    useEffect(() => {
      setLoading(true);
      let isMounted = true;
      const controller = new AbortController();
      const getKatalogs = async () => {
          try {
              const response = await axiosPrivate.post("/products", 
              JSON.stringify({'name': 'getAllProducts', 'param': {
                'companyID': '11',
                'katalogID': `${ktlg}`,
                'kathegorieID': `${katn}`,
                'records': `${perPage}`,
                'offset': `${pageNum}`
              }}),
              {
                  signal: controller.signal
              });
              isMounted && setProducts(response.data);
  
          } catch(err) {
              // console.error(err);
          }
      }
      // if(effectRun.current){
        getKatalogs();
      // }
      return () => {
          console.log('cleanup function');
          isMounted = false;
          controller.abort();
          effectRun.current = true;
      }
  },[perPage, debouncedSearch])

  return (
    <div className="flex-grow overflow-y-auto content-start bg-slate-200 w-full max-w-4xl mx-auto">
        <span className='my-10 min-h-[1px] flex flex-row justify-center relative'>
          <h1 className='mb-10 text-4xl text-center text-blue-400'>
            {t('Products')}
          </h1>
          <button className="mx-3 p-3 text-lg rounded-tl-lg text-black bg-white border-2 border-green-900 absolute right-1"
                  onClick={() => navigate(-1)}
          >
            {t('back')}
          </button>
        </span>

        {products.map((product, i) => {
            return  <Product key={i} 
                    language={language}
                    {...product} />;

          })}

    </div>
  )
}

export default Products
