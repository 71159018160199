import { Link } from "react-router-dom";
import { t } from "i18next";

const Footer = ({language}) => {
  return (

<footer className="p-4 bg-white shadow-md  md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-700">
    <span className="flex flex-col text-sm text-gray-500 sm:text-left dark:text-gray-400">
        <p>
            © 2023 <a href="https://www.wilvorst.de/" className="hover:underline">WILVORST Herrenmoden GmbH</a>
        </p>
        <p>
            Stettinerstr. 6-8
        </p>
        <p>
            37154 Northeim
        </p>  
    </span>

    <ul className="flex flex-col items-left mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <Link to="https://www.wilvorst.de/impressum" target={'_blank'}>{t('Impress')}</Link>
        </li>
        <li>
            <Link to="https://www.wilvorst.de/datenschutz" target={'_blank'}>{t('Privacy Policy')}</Link>
        </li>
    </ul>
</footer>

  )
}

export default Footer