import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import { useState } from "react";

import Header from "./Header";
import Footer from "./Footer";

import React from 'react'

const Layout = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState("de");
  return (
    <main className="h-screen flex flex-col bg-slate-50 dark:bg-slate-900 dark:text-white sm:scroll-smooth">
        <Header language={language}
            setLanguage={setLanguage}
            i18n={i18n}
            />
        <Outlet context={{language}}/>
        <Footer language={language}/>      
    </main>
  )
}

export default Layout