import { Link } from 'react-router-dom';
import { DE } from 'country-flag-icons/react/3x2';
import { GB } from 'country-flag-icons/react/3x2';

const LanguageDropdown = ({ i18n, setLanguage, language }) => {
    
    const handleLangChange = evt => {
        evt.preventDefault();
        const lang = evt.target.dataset.value;
        setLanguage(lang);
        i18n.changeLanguage(lang);
      };
  return (

    <div className="relative w-full group flex justify-center">
        <button type="button" className='w-full'>{language === 'de' ? <DE className='mr-1 w-8' /> : <GB className='mr-1 w-8' />}</button>
        <div className="absolute top-5 hidden group-hover:block hover:block w-20 shadow-lg">
            <div  className="mt-5 w-full bg-white divide-y divide-gray-100 shadow  dark:bg-gray-700 dark:divide-y">
                <ul className="w-full py-2 text-lg text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    <li>
                        <Link to='' className='flex p-2 mx-2 hover:bg-gray-700 dark:hover:text-white hover:border-b-[1px] hover:border-b-white' onClick={handleLangChange} data-value="de">
                            <DE className='mr-1' />                            
                            DE
                        </Link>
                    </li>
                    <li>
                        <Link to='' className='flex p-2 mx-2 hover:bg-gray-700 dark:hover:text-white hover:border-b-[1px] hover:border-b-white' onClick={handleLangChange} data-value="en">
                            <GB className='mr-1' />
                            EN
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
            

  )
}

export default LanguageDropdown