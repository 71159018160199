import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from "react-router-dom";



const Kataloge = ({language}) => {
  const [t] = useTranslation();
  const [katalogs, setKatalogs] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const effectRun = useRef(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getKatalogs = async () => {
        try {
            const response = await axiosPrivate.post("/katalogs", 
            JSON.stringify({'name': 'getAllKatalogs', 'param': {}}),
            {
                signal: controller.signal
            });
            isMounted && setKatalogs(response.data);

        } catch(err) {
            // console.error(err);
        }
    }
    // if(effectRun.current){
      getKatalogs();
    // }
    return () => {
        isMounted = false;
        controller.abort();
        effectRun.current = true;
    }
},[])


    const handleToggleChild = (id) => {
      const parent = document.getElementById(`parent-${id}`);
      const collapsible = document.getElementById(`collapse-${id}`);
      const plus = document.getElementById(`plus-${id}`);
      const minus = document.getElementById(`minus-${id}`);
      parent.classList.toggle('open');
      collapsible.classList.toggle('h-0');
      collapsible.classList.toggle('opacity-0');
      plus.classList.toggle('hidden');
      minus.classList.toggle('hidden');
    };
//    {isLoading && <p className="text-black text-center">{t('loading')}</p>}
//    {fetchError && <p className="text-black text-center">{t('loading')}</p>}
//    {!isLoading && !fetchError &&     xxxxxx }

  return (
    <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 w-full max-w-4xl mx-auto">
        <div className='mt-10 min-h-[10px]'></div>
        <h1 className='mb-10 text-4xl text-center text-blue-400'>deen{language}{t('Catalogs')}</h1>
          {katalogs.map((katalog, i) => {
            return (
            <div key={i} className="w-1/3">
                <div id={`parent-${i}`} className='flex flex-row border-2 border-slate-900 text-black text-lg p-3 ml-3 mb-3 cursor-pointer bg-slate-50 shadow-lg' 
                      onClick={() => handleToggleChild(i)}
                >
                    <p>{katalog.nutext}</p>
                    <span id={`plus-${i}`} className="flex-grow text-right">➕</span>
                    <span id={`minus-${i}`} className="flex-grow text-right hidden">➖</span>
                    
                </div>                    
                <div id={`collapse-${i}`} className="flex flex-col h-0 opacity-0 overflow-hidden transition-opacity ease-in-out duration-300">
                    {katalog.kathegory.map((kath, j) => {
                        return(
                        <Link 
                          key={j} 
                          className='ml-10'
                          to='/products'
                          state={{ktlg: `${kath.b1ktlg}`,
                                  katn: `${kath.b1katn}`}
                              }                                  
                        >
                          <div className="text-lg text-blue-900 bg-white border-2 border-blue-900 rounded-tl-lg mb-3 w-auto p-3">
                            { kath.b1katb }
                          </div>
                        </Link>
                        )
                    })}
                </div>

            </div>)
          })}
       </div>
  )
}

export default Kataloge