import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Formenstamm = () => {
  const [t] = useTranslation();
  const [formenstamms, setFormenstamms] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const effectRun = useRef(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getFormenstamms = async () => {
        try {
            const response = await axiosPrivate.post("/formenstamm", 
            JSON.stringify({'name': 'getAllFormenstamms', 'param': {}}),
            {
                signal: controller.signal
            });
            isMounted && setFormenstamms(response.data);

        } catch(err) {
            // console.error(err);
        }
    }
    // if(effectRun.current){
      getFormenstamms();
    // }
    return () => {
        isMounted = false;
        controller.abort();
        effectRun.current = true;
    }
},[])
                                                         
  return (
    <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 w-full max-w-4xl mx-auto">
      <div className='mt-10 min-h-[10px]'></div>
      <h1 className='mb-10 text-4xl text-center text-blue-400'>{t('Styles')}</h1>
      <div className="flex flex-row justify-center w-full relative">
      </div>
            {formenstamms.map((formenstamm, i) => {
            return (
            <div key={i} className="p-5 text-black dark:text-white">
              <h3>
                {formenstamm.beschreibung}
              </h3>
              <div className="flex flex-row flex gap-4">
              <p className='gap-4'>
                {formenstamm.form1}-{formenstamm.form2}-{formenstamm.form3}
                <br />
                {formenstamm.saison} / {formenstamm.jahr}
              </p>
              </div>
              <div className="border-b border-slate-900 dark:border-slate-50 mx-5 mt-3"></div>
            </div>)
          })}
    </div>
  )
}

export default Formenstamm