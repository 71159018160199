import { t } from 'i18next';

const ItemsPerPage = ({ perpage, setPerPage, className, perPageValues }) => {

    
    const handleChange = (e) => {
        setPerPage(e);
      }

  return (
    <form onSubmit={(e) => e.preventdefault()} className={`${className}`}>
        <label htmlFor="toDisplay" className='block text-sm font-medium text-gray-900 dark:text-white'>{t('entries per page')}</label>
        <select
            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'   
            id="toDisplay"
            value={perpage}
            onChange={(e) => handleChange(e.target.value)}
            >
            {perPageValues.map((value, i) => {
              return <option key={i} value={value}>{value}</option> 
            })} 
       </select>
  </form>
  )
}

export default ItemsPerPage