import { Link } from "react-router-dom"
import NavDropdown from "./NavDropdown";
import DropdownItem from "./DropdownItem";
import LanguageDropdown from "./LanguageDropdown";
import { t } from 'i18next';
import logo from './images/wilvorstlogo.svg';


const Header = ({i18n, setLanguage, language}) => {
    
    const handleMobileMenuToggle = () => {
        
        const hamburgerButton = document.getElementById('hamburger-button');
        const mobileMenu = document.getElementById('mobile-menu');
        mobileMenu.classList.toggle('hidden');
        mobileMenu.classList.toggle('flex');
        hamburgerButton.classList.toggle('toggle-btn');

    }
  return (
    <header className="bg-white dark:bg-gray-700 text-slate-900 dark:text-white sticky top-0 z-10 shadow-md">
        <section className="max-w-4xl mx-auto p-4 flex justify-between items-center">
            <Link to="/" className="flex flex-col items-center"><img src={logo} alt="Logo" className="max-h-16 bg-white p-1" /><p className="text-base font-light">Stylemanager</p></Link>
            <>
                <button 
                    id="hamburger-button" 
                    className="text-3xl md:hidden cursor-pointer relative w-8 h-8"
                    onClick={handleMobileMenuToggle}
                    >
                    {/* &#9776; */}
                    <div className="bg-white w-8 h-1 rounded absolute top-4 -mt-0.5
                                     transition-all
                                     duration-500 
                                     before:content-['']
                                     before:bg-white 
                                     before:w-8 
                                     before:h-1  
                                     before:rounded 
                                     before:absolute 
                                     before:-translate-x-4
                                     before:-translate-y-3
                                     before:transition-all
                                     before:duration-500
                                     after:content-['']
                                     after:bg-white 
                                     after:w-8 
                                     after:h-1 
                                     after:rounded 
                                     after:absolute 
                                     after:-translate-x-4
                                     after:translate-y-3
                                     after:transition-all
                                     after:duration-500">
                    </div>
                </button>
                <nav className="hidden md:flex space-x-8 text-xl whitespace-nowrap" aria-label="main">
                    <div><Link to="/" className="hover:opacity-80">Home</Link></div>
                    <div><Link to="kataloge" className="hover:opacity-80">{t('Catalogs')}</Link></div>
                    <div><Link to="formenstamm" className="hover:opacity-80">{t('Styles')}</Link></div>
                    <NavDropdown title={t('myB2B')}>
                        <DropdownItem path="login">{t('Login')}</DropdownItem>
                        <DropdownItem path="register">{t('Register')}</DropdownItem>
                        <DropdownItem path="">{t('My customer data')}</DropdownItem>
                        <DropdownItem path="">{t('My basket')}</DropdownItem>
                        <DropdownItem path="">{t('My orders')}</DropdownItem>
                        <DropdownItem path="cloths">{t('cloth availabilty')}</DropdownItem>
                        <DropdownItem path="">{t('order tracking')}</DropdownItem>
                        <DropdownItem path="">{t('despatch dates for production orders')}</DropdownItem>
                    </NavDropdown>
                    <LanguageDropdown language={language}
                                      setLanguage={setLanguage}
                                      i18n={i18n}
                    />
                </nav>
            </>
        </section>
        <section 
            id="mobile-menu" 
            className="absolute top-68 bg-black w-full text-5xl flex-col justify-content-center origin-top animate-open-menu hidden"
            onClick={handleMobileMenuToggle}
        >
            {/* <button className="text-8xl self-end px-6">
                &times;
            </button> */}
            <nav className="flex flex-col min-h-screen items center py-8" aria-label="mobile"> 
                <Link to="/" className="w-full text-center py-6 hover:opacity-80">Home</Link>
                <Link to="kataloge" className="w-full text-center py-6 hover:opacity-80">{t('Catalogs')}</Link>
                <Link to="formen" className="w-full text-center py-6 hover:opacity-80">{t('Styles')}</Link>
                <LanguageDropdown language={language}
                                      setLanguage={setLanguage}
                                      i18n={i18n}
                    />
            </nav>
        </section>
    </header>
  )
}

export default Header