import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useDebounce from "../../hooks/useDebounce";
import { Link } from "react-router-dom";
import PaginationButtons from "../PaginationButtons";
import ItemsPerPage from "../ItemsPerPage";
import Cloth from "../Cloth";



const Cloths = ({i18n, setLanguage, language}) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [cloths, setCloths] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const effectRun = useRef(false);
  const perPageValues = [10, 15, 20];
  const [perPage, setPerPage] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    const controller = new AbortController();
    const getItemCount = async () => {
        try {
            const response = await axiosPrivate.post("/cloths", 
            JSON.stringify({'name': 'getAllCloths', 'param': {
              'companyID': '11',
              'records': `${perPage}`,
              'offset': `${pageNum}`
            }}),
            {
                signal: controller.signal
            });
            isMounted && setItemCount(parseInt(Math.ceil(response.data[0]['count']/`${perPage}`), 10));
        } catch(err) {
            // console.error(err);
        }
    }
    // if(effectRun.current){
      getItemCount();
    //}
    return () => {
        isMounted = false;
        controller.abort();
        effectRun.current = true;
    }
},[])

useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getItemCount = async () => {
        try {
            const response = await axiosPrivate.post("/cloths", 
            JSON.stringify({'name': 'getAllCloths', 'param': {
              'companyID': '11',
              'artikel': `${debouncedSearch}`,
              'records': `${perPage}`,
              'offset': 0
            }}),
            {
                signal: controller.signal
            });
            isMounted && setItemCount(parseInt(Math.ceil(response.data[0]['count']/`${perPage}`), 10));
            console.log(itemCount);
            // setLoading(false);
        } catch(err) {
            // console.error(err);
        }
    }
    // if(effectRun.current){
      getItemCount();
      setPageNum(0);
    //}
    return () => {
        isMounted = false;
        controller.abort();
        effectRun.current = true;
    }
},[perPage, debouncedSearch])

useEffect(() => {
  let isMounted = true;
  const controller = new AbortController();
  const getCloths = async () => {
      try {
          const response = await axiosPrivate.post("/cloths", 
          JSON.stringify({'name': 'getAllCloths', 'param': {
            'companyID': '11',
            'artikel': `${debouncedSearch}`,
            'records': `${perPage}`,
            'offset': `${pageNum}`
          }}),
          {
              signal: controller.signal
          });
          isMounted && setCloths(response.data);
          setLoading(false);
      } catch(err) {
          // console.error(err);
      }
  }
  // if(effectRun.current){
    getCloths();
  // }
  return () => {
      isMounted = false;
      controller.abort();
      effectRun.current = true;
  }  
}, [itemCount, pageNum])

const handleClearSearch = (e, el) => {
  e.preventDefault();
  var element = document.getElementById(el);
  element.value="";
  setSearch('');
}




  return (
    <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 w-full max-w-4xl mx-auto">
      <h1 className='mb-10 text-4xl text-center text-blue-400'>{t('ClothAvailability')}</h1>
      <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 w-full max-w-4xl mx-auto">
      {loading  ? (
        <div className="text-center text-5xl">Loading...</div>
      ) : (
        <>
        <table className="min-w-full text-left text-sm font-light">
          <thead className="border border-solid bg-white font-medium dark:border-neutral-500 dark:bg-neutral-600">
            <tr>
              <th scope="col" className="w-1/6 px-6 py-4">{t('fabric number')}</th>
              <th scope="col" className="w-1/12 px-6 py-4">{t('stock')}</th>
              <th scope="col" colSpan="2" className="px-6 py-4">{t('further deliveries')}</th>
              <th scope="col" colSpan="4" className="px-6 py-4">
                <form>   
                  <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                  <div class="relative">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                      </div>
                      <input 
                        onChange={(e) => setSearch(e.target.value)}
                        type="search" 
                        id="fabric-search" 
                        class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-slate-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder={t('search fabric')} 
                        required />
                      <button 
                        onClick={(e) => handleClearSearch(e, 'fabric-search')}
                        class="text-white absolute right-2.5 bottom-2.5 bg-sky-600 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-blue-800"
                      >X</button>
                  </div>
                </form>
              </th>
            </tr>
          </thead>
          <tbody>
          {itemCount>0 && !loading ? cloths.map((cloth, i) => {
            return (<Cloth key={i} {...cloth} />)
            })
          : <tr><td>no records...</td></tr>}
          </tbody>
        </table>
        </>
      )}
      {itemCount>0 ?  
        <div className="flex">
          <div className="flex-grow">
            <PaginationButtons
                itemCount={itemCount}
                pageNum={pageNum}
                setPageNum={setPageNum}
            />
          </div>
          <ItemsPerPage className="flex w-1/6 mt-8 mb-4 mr-1"
                          setPerPage={setPerPage}
                          perPage={perPage}
                          perPageValues={perPageValues}/>
        </div>
      : <></> }

      </div>
  </div>
  )
}

export default Cloths