import axios from "axios";

const BASE_URL = "https://api-stylemanager.wilvorst.net";

export default axios.create({
    baseURL: BASE_URL,    
    headers: {"Content-Type": "application/json"},
    withCredentials: true
});
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {"Content-Type": "application/json"},
    withCredentials: true
});