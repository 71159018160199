export const TRANSLATIONS_DE = {
    "home": "Home",
    "Login": "einloggen",
    "Login Page": "Login Seite",
    "Welcome to React": "Wilkommen!!! zu React",
    "userlogin": "Benutzer Login",
    "Users List": "Benutzer Liste",
    "List Users": "Benutzer",
    "Add User": "Benutzer hinzufügen",
    "Username": "Benutzername",
    "Email Address": "Email Adresse",
    "Password": "Passwort",
    "Usergroup": "Benutzergruppe",
    "Select Usergroup": "Benutzergruppe auswählen",
    "Admin": "Administrator",
    "Staffmember": "Mitarbeiter",
    "Sales Representative": "Vertreter",
    "Customer": "Kunde",
    "About": "Über uns",
    "Impress": "Impressum",
    "Privacy Policy": "Datenschutzerklärung",
    "Catalogs": "Kataloge",
    "loading": "lade Daten",
    "Styles": "Formen",
    "Products": "Produkte",
    "back": "zurück",
    "Register": "Registrieren",
    "myB2B": "Mein B2B",
    "My customer data": "Benutzerdaten", 
    "My basket": "Warenkorb",        
    "My orders": "Bestellungen",        
    "cloth availabilty": "Oberstoff Verfügbarkeit",
    "order tracking": "Order Tracking",   
    "despatch dates for production orders": "Anfertigungs Termine",
    "ClothAvailability": "Stoffverfügbarkeit",
    "further deliveries": "weitere Lieferungen",
    "fabric number": "Artikelnummer",
    "stock": "Bestand",
    "entries per page": "Einträge pro Seite",
    "search fabric": "Artikel suchen",
    "search": "suchen",
};
