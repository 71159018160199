import { useRef, useState, useEffect } from "react"
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "../../api/axios";



const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = "/reactusers";

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect (() => {
        userRef.current.focus();
    },[])
    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    },[user])
    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    },[email])
    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        const match = (pwd === matchPwd) && pwd.length >> 0 && PWD_REGEX.test(pwd);
        setValidMatch(match);
    },[pwd, matchPwd])
    useEffect(() => {
        setErrMsg('');
    },[user, pwd, matchPwd])

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        const v1 = USER_REGEX.test(user);
        const v2 = EMAIL_REGEX.test(email);
        const v3 = PWD_REGEX.test(pwd);
        if(!v1 || !v2 || !v3){
            setErrMsg('invalid Entry!');
            return;
        }
        try {
            axios.defaults.withCredentials = true;
            const response = await axios.post("https://api-stylemanager.wilvorst.net/reactusers", 
                JSON.stringify({username: user, email: email, password: pwd, usergroup: 1}),
                {
                    headers: {'Content-Type': 'application/json'}
     
                });
                console.log(response.data);
                console.log(response.accessToken);
                console.log(JSON.stringify(response));
                setSuccess(true);
        } catch(err) {
            if(!err?.response){
                setErrMsg('No server response');
            } else if(err.response?.status === 409) {
                setErrMsg('Username already taken');
            } else {
                setErrMsg('Registration failed');
            }
        }
    }
  return (

    <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 dark:text-white w-full max-w-4xl mx-auto">
        {success ? (
            <section>
                <h1>Success!</h1>
                <p><Link to="signin">Signin</Link></p>
            </section>
        ) : (
        <section className="flex flex-col text-center justify-items-center">
            <p ref={errRef} className={errMsg ? "text-red" : "hidden"} aria-live="assertive">{errMsg}</p>
            <h1>Register</h1>
            <form className="flex flex-col text-black w-1/3 mx-auto"
                  onSubmit={handleSubmit}>
                <label htmlFor="username" className=" flex flex-row text-black dark:text-white mr-3">
                    Username:
                    <span className={validName ? "valid" : "hidden"}><FontAwesomeIcon className="text-green-500" icon={faCheck} />
                    </span>
                    <span className={validName || !user ? "hidden" : "invalid"}><FontAwesomeIcon className="text-red-500" icon={faTimes} /></span>
                </label>
                <input type="text"
                    id="username"
                    className="m-3 p-3 min-h-[48px] border-2 border-blue-900"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    required
                    aria-invalid={validName ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)} />
                <p id="uidnote" className={userFocus && user && !validName ? "text-red-500 bg-slate-900 dark:bg-slate-50" : "hidden"}><FontAwesomeIcon icon={faInfoCircle} />
                    4 to 24 characters.<br />
                    Must begin with a letter.<br />
                    Letters, numbers, underscores, hyphens allowed.
                </p>
                <label htmlFor="email" className=" flex flex-row text-black dark:text-white mr-3">
                    Email:
                    <span className={validEmail ? "valid" : "hidden"}><FontAwesomeIcon className="text-green-500" icon={faCheck} />
                    </span>
                    <span className={validEmail || !email ? "hidden" : "invalid"}><FontAwesomeIcon className="text-red-500" icon={faTimes} /></span>
                </label>
                <input type="text"
                    id="email"
                    className="m-3 p-3 min-h-[48px] border-2 border-blue-900"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    aria-invalid={validEmail ? "false" : "true"}
                    aria-describedby="emailnote"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)} />
                <p id="emailnote" className={emailFocus && email && !validEmail ? "text-red-500 bg-slate-900 dark:bg-slate-50" : "hidden"}><FontAwesomeIcon icon={faInfoCircle} />
                    This is not a valid Email.
                </p>
                <label htmlFor="password" className=" flex flex-row text-black dark:text-white mr-3">
                    Password:
                    <span className={validPwd ? "valid" : "hidden"}><FontAwesomeIcon className="text-green-500" icon={faCheck} />
                    </span>
                    <span className={validPwd || !pwd ? "hidden" : "invalid"}><FontAwesomeIcon className="text-red-500" icon={faTimes} /></span>
                </label>
                <input type="password"
                    id="password"
                    className="m-3 p-3 min-h-[48px] border-2 border-blue-900"
                    onChange={(e) => setPwd(e.target.value)}
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)} />
                <p id="pwdnote" className={pwdFocus && !validPwd ? "text-red-500 bg-slate-900 dark:bg-slate-50" : "hidden"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    8 to 26 characters.<br />
                    Must include uppercase and lowercase letters, a number and a special character.<br />
                    Allowed special characters: <span aria-label="exclamation mark">!</span>
                                                <span aria-label="at symbol">@</span>
                                                <span aria-label="hashtag">#</span>
                                                <span aria-label="dollar sign">$</span>
                                                <span aria-label="percent">%</span>
                </p>
                <label htmlFor="confirm_pwd" className=" flex flex-row text-black dark:text-white mr-3">
                    Confirm Password:
                    <span className={validMatch  ? "valid" : "hidden"}><FontAwesomeIcon className="text-green-500" icon={faCheck} />
                    </span>
                    <span className={validMatch || !matchPwd ? "hidden" : "invalid"}><FontAwesomeIcon className="text-red-500" icon={faTimes} /></span>
                </label>
                <input type="password"
                    id="confirm_pwd"
                    className="m-3 p-3 min-h-[48px] border-2 border-blue-900"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)} />
                <p id="confirmnote" className={matchFocus && !validMatch ? "text-red-500 bg-slate-900 dark:bg-slate-50" : "hidden"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Must match first password input field.
                </p>

                <button className="p-3 dark:bg-slate-50 dbg-slate-900 dark:text-blue-900 text-white border-2 border-blue-900 rounded-tl-lg" disabled={!validName || !validEmail || !validPwd || !validMatch ? true : false}>Sign Up</button>
            </form>
            <p>Already registered?<br />
            <Link to="login">Login</Link></p>
        </section>
        )}
    </div>
    
  )
}

export default Register