
const NavDropdown = ({ children, title}) => {

    return (
    <div className="relative w-full group flex justify-items-center">
        <button className="w-full whitespace-nowrap" type="button">{title}</button>
        <div className="absolute top-5 hidden group-hover:block hover:block shadow-lg">
            <div  className="mt-5 w-full bg-white divide-y divide-gray-100 shadow  dark:bg-gray-700">
                <ul className="w-full py-2 text-lg text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    {children}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default NavDropdown