import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <div className="flex-grow overflow-y-auto content-start bg-slate-50 dark:bg-slate-900 dark:text-white w-full max-w-4xl mx-auto">
      <div className='mt-10 min-h-[10px]'></div>
      <h1 className='mb-10 text-4xl text-center text-blue-400'>Unauthorized</h1>
      <p className="px-5 text-justify text-lg">
        <button onClick={goBack}> Go Back</button>
      </p>
    </div>
  )
}

export default Unauthorized