import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


const Users = () => {
    const [ users, setUsers ] = useState();
    const axiosPrivate = useAxiosPrivate();
    const effectRun = useRef(false);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getUsers = async () => {
            try {
                const response = await axiosPrivate.post("/customer",
                JSON.stringify({name: 'getAllCustomers', param: {}}),
                {
                    signal: controller.signal
                });
                isMounted && setUsers(response.data);
                
            } catch(err) {
                // console.error(err);
            }
        }

        // if(effectRun.current){
            getUsers();
        // }
        return () => {
            isMounted = false;
            controller.abort();
            effectRun.current = true;
        }
    },[])


  return (
    <article>
        <h2>Users List</h2>
        {users?.length
            ? (
                <ul>
                    {users.map((user, i) => 
                        <li key={i}>{user?.email}</li>
                    )}
                </ul>
            ) : (
                <p>no Users to display</p>
            )}
    </article>
  )
}

export default Users