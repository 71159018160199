import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { motion } from "framer-motion";

function PaginationButtons({ setPageNum, pageNum, itemCount }) {
    const handlePageClick = ({ selected }) => {
        setPageNum(selected);
      };
    const paginationVariants = {
        hidden: {
            opacity: 0,
            y: 200,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
            type: "spring",
            stiffness: 260,
            damping: 20,
            duration: 2,
            },
        },
    };
    const showNextButton = pageNum !== itemCount - 1;
    const showPrevButton = pageNum !== 0;    
return (
    <motion.div
        variants={paginationVariants}
        initial="hidden"
        animate="visible"
    >
    <ReactPaginate
        breakLabel={<span className="mr-4">...</span>}
        nextLabel={
          showNextButton ? (
            <span className="border border-solid border-lightGray hover:bg-teal-600 hover:text-black w-10 h-10 flex items-center justify-center rounded-md">
              <BsChevronRight />
            </span>
          ) : null
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={itemCount}
        previousLabel={
          showPrevButton ? (
            <span className="border border-solid border-slate-50  hover:bg-teal-600 hover:text-black w-10 h-10 flex items-center justify-center rounded-md mr-4">
              <BsChevronLeft />
            </span>
          ) : null
        }
        containerClassName="flex items-center justify-center mt-8 mb-4"
        pageClassName="border border-solid border-slate-50 hover:bg-teal-600 hover:text-black text-lg w-10 h-10 flex items-center justify-center rounded-md mr-4"
        activeClassName="text-white bg-purple-400"
      />
    
    </motion.div>    
  )
}

export default PaginationButtons