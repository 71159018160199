export const TRANSLATIONS_EN = {
    "home": "Home",
    "Login": "Login",
    "Login Page": "Login Pages",
    "Welcome to React": "Welcome to React",
    "userlogin": "User Login",
    "Users List": "User List",
    "List Users": "Users",
    "Add User": "Add User",
    "Username": "Username",
    "Email Address": "Email Address",
    "Password": "Password",
    "Usergroup": "Usergroup",
    "Select Usergroup": "select Usergroup",
    "Admin": "Administrator",
    "Staffmember": "Staffmember",
    "Sales Representative": "Sales Representative",
    "Customer": "Customer",
    "About": "About",
    "Impress": "Impress",
    "Privacy Policy": "Privacy Policy",
    "Catalogs": "Catalogs",
    "loading": "loading",
    "Styles": "Styles",
    "Products": "Products",
    "back": "back",
    "Register": "Register",
    "myB2B": "My B2B",
    "My customer data": "My customer data", 
    "My basket": "My basket",        
    "My orders": "My orders",        
    "cloth availabilty": "cloth availabilty",
    "order tracking": "order tracking",   
    "despatch dates for production orders": "despatch dates for production orders",
    "ClothAvailability": "Cloth Availabitiy",
    "further deliveries": "further deliveries",
    "fabric number": "fabric number",
    "stock": "stock",
    "entries per page": "entries per page",
    "search fabric": "search fabric",
    "search": "search",
};
