import { useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';

const Product = ({i, bstxt1, bstxt2, bstxt3, bsfm1, bsfm2, bsfm3, bsscli, bshash, bsnart, bsnfar, bspl, bsposh, bsposu, bsvpg}) => {
    const {language} = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);
    const effectRun = useRef(false);
    const axiosPrivate = useAxiosPrivate();
    const [productDatas, setProductDatas] = useState([]);
    const [childs, setChilds] = useState([]);
    const [features, setFeatures] = useState();
    const [produkttyp, setProdukttyp] = useState();
    const [produktkategorie, setProduktkategorie] = useState();

    const [isOpen, setOpen] = useState(true);
    const [currentImageIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        const censhareController = new AbortController();
        const getProductsDatas = async () => {
            try {
                const censhareresponse = await axiosPrivate.post("/censhare", 
                JSON.stringify(
                  {
                    "name": "getProductDatas",
                    "param": {
                      "form": `${bsfm1.trim()}-${bsfm2.trim()}-${bsfm3.trim()}-${bsscli.trim()}`,
                      "artikel": `${bsnart.trim()}`,
                      "farbe": `${bsnfar}`,
                      "saison": "1",
                      "jahr": "2023"
                    }
                }
                ),
                {
                    signal: censhareController.signal
                });
                console.log(censhareresponse.data);
                isMounted && setProductDatas(censhareresponse.data);
                if(Object.keys(censhareresponse.data.features).length>0) {
                    let data = [];
                    Object.keys(censhareresponse.data.features).map((key) => {
                        data[key]=censhareresponse.data.features[key];
                    })
                    setFeatures(data);
                }
                if(Object.keys(censhareresponse.data.d2c_produkttyp).length>0) {
                    let data = [];
                    Object.keys(censhareresponse.data.d2c_produkttyp).map((key) => {
                        data[key]=censhareresponse.data.d2c_produkttyp[key];
                    })
                    setProdukttyp(data);
                }
                if(Object.keys(censhareresponse.data.d2c_produktkategorie).length>0) {
                    let data = [];
                    Object.keys(censhareresponse.data.d2c_produktkategorie).map((key) => {
                        data[key]=censhareresponse.data.d2c_produktkategorie[key];
                    })
                    setProduktkategorie(data);
                }
                if(Object.keys(censhareresponse.data.childs).length>0) {
                    let data = [];
                    Object.keys(censhareresponse.data.childs).map((key) => {
                        data[key]=censhareresponse.data.childs[key];
                    })
                    setChilds(data);
                }
                setIsLoading(false);
    
            } catch(err) {
                // console.error(err);
            }
        }
        // if(effectRun.current){
          getProductsDatas();
  
        return () => {
            console.log('cleanup function');
            isMounted = false;
            censhareController.abort();
            effectRun.current = true;
        }
    },[])

    useEffect(() =>{
        // console.log(childs);
    },[childs])

  return (
        <div key={i} className="text-black border-b-2 border-black my-3 p-3">

            <h3>{bstxt1}</h3>
            <h5>{bsvpg}</h5>
            <p className="text-base font-extralight">{bstxt2}</p>
            <p className="text-base font-extralight">{bstxt3}</p>

            {isLoading ? <div>is Loading</div> 
            :
            <div>
                <p>{productDatas.artickle}</p>
                <p>{productDatas.color}</p>
                <p>{productDatas.name}</p>
                <p>{language}</p>
                <p><b><u>{produktkategorie ? produktkategorie[`${language}`] : null }-{produkttyp ? produkttyp[`${language}`] : null}</u></b></p>
                <p>{productDatas.features && productDatas.features['bugatti:c_produktbeschreibung_extern:de'] ? productDatas.features['bugatti:c_produktbeschreibung_extern:de'].value : null}</p>
                <p>
                    <Gallery>
                        <div className="w-1/3 grid grid-cols-3 gap-1"
               
                        >
                        {Object.keys(childs).map(key => {
                            return  <>
                                {key.endsWith('_f.jpg') || key.endsWith('_b.jpg') || key.endsWith('_add1.jpg') || key.endsWith('_add2.jpg')? 
                                    <Item
                                        cropped
                                        original={`https://mm-brinkmann-gruppe.s3.eu-central-1.amazonaws.com/assets/asset/id/${childs[key].id}/storage/jpeg_lowres_extern_von_master/${key}`}  
                                        thumbnail={`https://mm-brinkmann-gruppe.s3.eu-central-1.amazonaws.com/assets/asset/id/${childs[key].id}/storage/thumbnail/${key}`} 
                                        width="859"
                                        height="1289"
                       
                                    >
                                        {({ ref, open }) => (
                                            <img className="w-20 h-20 object-cover cursor-pointer"
                                                
                                                ref={ref} 
                                                onClick={open} 
                                                src={`https://mm-brinkmann-gruppe.s3.eu-central-1.amazonaws.com/assets/asset/id/${childs[key].id}/storage/thumbnail/${key}`} />
                                        )}
                                    </Item>
                                    // storage/jpeg_lowres_extern_von_master
                                    // storage/preview
                                    // storage/thumbnail
                                    : 
                                    <></>
                                }
                                </>;
                        })}
                        </div>
                    </Gallery>
                
                
                
                
                </p>


            </div>
            }

        </div>

  )
}

export default Product